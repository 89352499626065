import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"products"}},[_c('ws-app-bar',{attrs:{"dark":""}}),_c(VSheet,{staticClass:"d-flex flex-column justify-space-between",attrs:{"color":"ws-black","dark":"","elevation":"0"}},[_c(VImg,{staticClass:"flex-grow section-md",attrs:{"eager":"","aspect-ratio":"4","position":_vm.$vuetify.breakpoint.mdAndUp ? '100% 55%' : '70%',"src":require('../assets/new-site/products/hero.jpg'),"xgradient":"to right, rgba(12 ,25 ,34, .99),  rgba(12 ,25 ,34, .0)","gradient":_vm.$vuetify.breakpoint.mdAndUp
          ? 'to right, rgba(12 ,25 ,34, .99),  rgba(12 ,25 ,34, .0)'
          : 'to right, rgba(12 ,25 ,34, .5),  rgba(12 ,25 ,34, .5)'}},[_c(VContainer,{staticClass:"py-14"},[_c(VRow,{attrs:{"wrap":""}},[_c(VCol,{staticClass:"px-8 px-md-0",attrs:{"cols":"12","md":"6","xlg":"5"}},[_c('h1',{staticClass:"mb-4 hidden-sm-and-down"},[_c('ws-gradient-text',{staticClass:"text-uppercase"},[_vm._v(" Welcome to the world of "),_c('br'),_vm._v(" logistics technology ")])],1),_c('h1',{staticClass:"mb-6 hidden-md-and-up"},[_vm._v(" Welcome to the world of "),_c('br'),_vm._v(" logistics technology ")]),_c('p',{staticClass:"body-1 font-weight-bold my-6 hidden-md-and-down"},[_vm._v(" We believe that technology is not only fundamental to delivering outstanding service in the current market, it’s the key driver for sustainable growth, propelling you to a lucrative future. This is why our platform offers innovative solutions that meet today’s goals, while also providing you with the tools, connections, and services for a successful tomorrow. ")]),_c('div',[_c(VBtn,{staticClass:"mr-3 mt-5 mt-md-0",attrs:{"color":"white","light":""},on:{"click":function($event){_vm.logGAEvent('Products', 'Get a quote', "Hero Banner");
                  _vm.$vuetify.goTo('#interest');}}},[_vm._v(" Get a Quote ")])],1)])],1)],1)],1)],1),_c('section',{staticClass:"section-lg"},[_c(VContainer,{staticClass:"pa-0"},_vm._l((_vm.productsItems),function(si,i){return _c(VRow,{key:i,staticClass:"row-wrapper d-flex align-items-center mx-5 mx-md-0",class:{ 'ws-odd-row': i % 2 }},[_c(VCol,{staticClass:"p-0",attrs:{"cols":"12","md":"6"}},[_c(VImg,{attrs:{"eager":"","src":si.imageUrl}})],1),_c(VCol,{staticClass:"px-5 px-lg-16",attrs:{"cols":"12","md":"6"}},[_c('h3',[_c('ws-gradient-text',{domProps:{"textContent":_vm._s(si.title)}})],1),_c('p',{staticClass:"body-1 font-weight-bold mt-3",domProps:{"textContent":_vm._s(si.description)}}),_c('div',[_c(VBtn,{attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.$router.push({
                  name: si.target,
                  params: { country: _vm.$route.params.country },
                })}}},[_vm._v(" How? ")])],1)])],1)}),1)],1),_c('section',{staticClass:"ws-banner ws-banner-pink section-lg white--text",attrs:{"id":"interest"}},[_c(VContainer,{attrs:{"dark":""}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('h2',{staticClass:"mb-6"},[_vm._v(" We Store."),_c('br'),_vm._v(" You Grow. ")]),_c('h3',{staticClass:"mb-5"},[_vm._v(" Solving the hassles of your storage and delivery. ")]),_c('h4',[_vm._v("We will do the heavy lifting for you!")])]),_c(VCol,{attrs:{"cols":"12","md":"6","lg":"4","offset-lg":"1"}},[_c('ws-common-form',{attrs:{"title":"Get a Call","title-color":"primary","color":"white","light":"","comments-label":"Comments / Questions","submit-color":"ws-green","form-type":_vm.types.CustomerInterest}})],1)],1)],1)],1),_c('ws-footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }