












































































































































import mixins from "vue-typed-mixins";
import MyMixin from "@/plugins/myMixin";
import { metadata } from "@/plugins/metadata";
import { types } from "@/services/westore/enums";

export default mixins(MyMixin).extend({
  name: "Products",
  mounted() {
    this.trackMyPageView();
  },
  metaInfo: {
    title: metadata.Products.title,
    htmlAttrs: {
      lang: "en-US",
    },
    meta: [
      { charset: "utf-8" },
      { name: "robots", content: "follow, index" },
      {
        name: "description",
        content: metadata.Products.description,
      },
      {
        name: "viewport",
        content: "width=device-width, initial-scale=1, user-scalable=no",
      },
    ],
  },
  methods: {
    showHideContactsCustom() {
      if (!this.globals.whatsappPopupVisible) {
        this.logGAEvent("Let's Chat", "Whatsapp open", `Products Page`);
      }
      this.globals.whatsappPopupVisible = !this.globals.whatsappPopupVisible;
    },
  },
  computed: {
    types() {
      return types;
    },
  },
  data() {
    return {
      productsItems:
        this.$route.params.country == "ae"
          ? [
              {
                id: "freight",
                title: "Freight",
                target: "Freight",
                description:
                  "In collaboration with our extensive network of global partners, we are able to ship your cargo cost-effectively and time-efficiently, by land, air, or sea.",
                imageUrl: require("../assets/new-site/products/product1.jpg"),
              },
              {
                id: "warehousing",
                title: "Warehousing",
                target: "Warehousing",
                description:
                  "Get a profound sense of security and flexibility as we connect you with countless warehouses and storage suppliers that offer on-demand space across the UAE.",
                imageUrl: require("../assets/new-site/products/product2.jpg"),
              },
              {
                id: "project-management",
                title: "Project Management",
                target: "ProjectManagement",
                description:
                  "We understand that some projects come with more challenges than others. Through our project management services, our experienced team will handle your cargo with special care while still ensuring timely delivery.",
                imageUrl: require("../assets/new-site/products/product3.webp"),
              },
              {
                id: "fulfillment",
                title: "Fulfillment",
                target: "Fulfillment",
                description:
                  "Leave all the picking, packing, labeling, and readying of your inventory up to us. With our fulfillment services, we get it done fast!",
                imageUrl: require("../assets/new-site/products/product4.png"),
              },
              {
                id: "transportation",
                target: "Transportation",
                title: "Transportation",
                description:
                  "Whether you require a pick-up, last mile delivery service, or transportation across the entire supply chain, we will not just meet your needs, we will exceed them.",
                imageUrl: require("../assets/new-site/products/product5.jpg"),
              },
              {
                id: "Customs-border",
                target: "CustomsClearance",
                title: "Customs & border services",
                description:
                  "Save yourself the time and energy spent on documentation by leaving all the paper work of international shipments to us. We have the proper know-how to optimize the process and avoid delays.",
                imageUrl: require("../assets/new-site/products/product8.png"),
              },
              {
                id: "LastMile",
                target: "LastMile",
                title: "Delivery service (last mile) ",
                description:
                  "Whether you are seeking last-mile, same-day, next-day or 4-hour delivery services, we’ve got you covered.",
                imageUrl: require("../assets/new-site/products/product7.png"),
              },
            ]
          : this.$route.params.country == "qa"
          ? [
              {
                id: "freight",
                title: "Freight",
                target: "Freight",
                description:
                  "In collaboration with our extensive network of global partners, we are able to ship your cargo cost-effectively and time-efficiently, by land, air, or sea.",
                imageUrl: require("../assets/new-site/products/product1.jpg"),
              },
              {
                id: "warehousing",
                title: "Warehousing",
                target: "Warehousing",
                description:
                  "Get a profound sense of security and flexibility as we connect you with countless warehouses and storage suppliers that offer on-demand space across the Qatar.",
                imageUrl: require("../assets/new-site/products/product2.jpg"),
              },
              {
                id: "project-management",
                title: "Project Management",
                target: "ProjectManagement",
                description:
                  "We understand that some projects come with more challenges than others. Through our project management services, our experienced team will handle your cargo with special care while still ensuring timely delivery.",
                imageUrl: require("../assets/new-site/products/product3.webp"),
              },
              {
                id: "fulfillment",
                title: "Fulfillment",
                target: "Fulfillment",
                description:
                  "Leave all the picking, packing, labeling, and readying of your inventory up to us. With our fulfillment services, we get it done fast!",
                imageUrl: require("../assets/new-site/products/product4.png"),
              },
              {
                id: "transportation",
                target: "Transportation",
                title: "Transportation",
                description:
                  "Whether you require a pick-up, last mile delivery service, or transportation across the entire supply chain, we will not just meet your needs, we will exceed them.",
                imageUrl: require("../assets/new-site/products/product5.jpg"),
              },
              {
                id: "Customs-border",
                target: "CustomsClearance",
                title: "Customs & border services",
                description:
                  "Save yourself the time and energy spent on documentation by leaving all the paper work of international shipments to us. We have the proper know-how to optimize the process and avoid delays.",
                imageUrl: require("../assets/new-site/products/product8.png"),
              },
            ]
          : [
              {
                id: "freight",
                title: "Freight",
                target: "Freight",
                description:
                  "In collaboration with our extensive network of global partners, we are able to ship your cargo cost-effectively and time-efficiently, by land, air, or sea.",
                imageUrl: require("../assets/new-site/products/product1.jpg"),
              },
              {
                id: "warehousing",
                title: "Warehousing",
                target: "Warehousing",
                description:
                  "Get a profound sense of security and flexibility as we connect you with countless warehouses and storage suppliers that offer on-demand space across the KWT.",
                imageUrl: require("../assets/new-site/products/product2.jpg"),
              },
              {
                id: "project-management",
                title: "Project Management",
                target: "ProjectManagement",
                description:
                  "We understand that some projects come with more challenges than others. Through our project management services, our experienced team will handle your cargo with special care while still ensuring timely delivery.",
                imageUrl: require("../assets/new-site/products/product3.webp"),
              },
              {
                id: "fulfillment",
                title: "Fulfillment",
                target: "Fulfillment",
                description:
                  "Leave all the picking, packing, labeling, and readying of your inventory up to us. With our fulfillment services, we get it done fast!",
                imageUrl: require("../assets/new-site/products/product4.png"),
              },
              {
                id: "transportation",
                target: "Transportation",
                title: "Transportation",
                description:
                  "Whether you require a pick-up, last mile delivery service, or transportation across the entire supply chain, we will not just meet your needs, we will exceed them.",
                imageUrl: require("../assets/new-site/products/product5.jpg"),
              },
              {
                id: "Customs-border",
                target: "CustomsClearance",
                title: "Customs & border services",
                description:
                  "Save yourself the time and energy spent on documentation by leaving all the paper work of international shipments to us. We have the proper know-how to optimize the process and avoid delays.",
                imageUrl: require("../assets/new-site/products/product8.png"),
              },
            ],
    };
  },
});
